// import Api from '../model/interceptors';

// const fetchData = async (url: string, method: string = 'get', data: any = null) => {
//     try {
        
//         const response = method === 'get' 
//             ? await Api[method](url)
//             : await Api[method](url, data);
//         return response.data;
//     } catch (error) {
//         console.error(`Error fetching ${url}:`, error);
//         throw error;
//     }
// };

// // Setup endpoints
// export const getPropertySubTypes = async () => fetchData('Setup/GetPropertyMasterSubTypesIncludingDetails');
// export const getGovernorate = async () => fetchData('Setup/GetGovernorate');
// export const getListingPurpose = async () => fetchData('Setup/GetListingPurpose');
// export const getPropertyMasterTypes = async () => fetchData('Setup/GetPropertyMasterTypes');
// export const getWaliyat = async () => fetchData('Setup/GetWilayat');
// export const getAllAreas = async () => fetchData('Setup/GetAllAreas');

// // Address endpoint
// export const getWilayatAndGovernateByAreaId = async (id: string) => fetchData(`Address/GetWilayatAndGovernateByAreaId?AreaId=${id}`);

// // Property filter endpoint
// export const getMaxUnitPrice = async () => fetchData('PropertyFilter/GetMaxUnitPrice');

// // Wilayat property endpoints
// export const getWilayatBuildingtop8List = async () => fetchData('WilayatProperty/WilayatBuildingtop8List');
// export const getFindRealEstateAgent = async () => fetchData('WilayatProperty/FindRealEstateAgent');

// // User endpoints
// export const SignUpUser  = async (userData: any) => fetchData('Users/RegisterFromWeb', 'post', userData);
// export const CheckAlreadyExistNumber  = async (number: string) => fetchData(`Users/UserAlreadyExist?p_phoneNumber=${number}`);


import Api from '../model/interceptors'
export const getPropertySubTypes = async () => {
    try {
        const response = await Api.get(`Setup/GetPropertyMasterSubTypesIncludingDetails`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching property subtypes:', error);
        throw error;
    }
};

export const getGovernorate = async () => {
    try {
        const response = await Api.get(`Setup/GetGovernorate`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching governorates:', error);
        throw error; 
    }
};

export const getListingPurpose = async () => {
    try {
        const response = await Api.get(`Setup/GetListingPurpose`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching getListingPurpose:', error);
        throw error;
    }
};
export const getPropertyMasterTypes = async () => {
    try {
        const response = await Api.get(`Setup/GetPropertyMasterTypes`)
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error;
    }
};
export const getWaliyat = async () => {
    try {
        const response = await Api.get(`Setup/GetWilayat`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error;
    }
};
export const GetWilayatAndGovernateByAreaId = async (id) => {
    try {
        const response = await Api.get(`Address/GetWilayatAndGovernateByAreaId?AreaId=${id}`);
     
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error;
    }
};
export const getAllAreas = async () => {
    try {
        const response = await Api.get(`Setup/GetAllAreas`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error; 
    }
};
export const getMaxUnitPrice = async () => {
    try {
        const response = await Api.get(`PropertyFilter/GetMaxUnitPrice`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error;
    }
};
export const getWilayatBuildingtop8List = async () => {
    try {
        const response = await Api.get(`WilayatProperty/WilayatBuildingtop8List`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error; 
    }
};
export const getFindRealEstateAgent = async () => {
    try {
        const response = await Api.get(`WilayatProperty/FindRealEstateAgent`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error;
    }
};
export const SignUpUser = async (userData) => {
    try {
        const response = await Api.post(`Users/RegisterFromWeb`, userData);
        return await response.data;
    } catch (error) {
        console.error('Error signing up user:', error);
        throw error;
    }
};

export const CheckAlreadyExistNumber = async (number) => {
    try {
        const response = await Api.get(`Users/UserAlreadyExist?p_phoneNumber=${number}`);
        return await response.data;
    } catch (error) {
        console.error('Error fetching GetPropertyMasterTypes:', error);
        throw error; 
    }
};