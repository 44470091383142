export default function Loading() {
  const loadingImg = `${process.env.REACT_APP_Asset_URL}/img/spinner.svg`;
  return (
      <div className="d-flex justify-content-center mt-5">
        <img
          className="img-fluid"
          width={"300px"}
          height={'500px'}
          src={loadingImg}
          alt="Mumtalikati"
        />
      </div>
  );
}
