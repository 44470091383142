// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popularsearchbtn {
  border: none;
  color: #767676;
}

.selectedpopularsearch {
  border-bottom: 2px solid #9e2a2b;
  color: #000;
}

.list {
  list-style-type: none;
}

.listcolor:hover {
  text-decoration: underline;
  cursor: pointer;
}

.listcolor {
  color: #345c66;
  list-style-type: none;
  font-size: 1.61rem;
  white-space: nowrap;
  max-width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  a{
    color: #345c66;
    text-decoration: none;
  }
}

.listpadding {
  padding-left: 0rem !important;
}

.omantext {
  font-size: 2.03rem;
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Styling/PopularSearches.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,gCAAgC;EAChC,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,uBAAuB;EACvB,gBAAgB;EAChB;IACE,cAAc;IACd,qBAAqB;EACvB;AACF;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,eAAe;AACjB","sourcesContent":[".popularsearchbtn {\r\n  border: none;\r\n  color: #767676;\r\n}\r\n\r\n.selectedpopularsearch {\r\n  border-bottom: 2px solid #9e2a2b;\r\n  color: #000;\r\n}\r\n\r\n.list {\r\n  list-style-type: none;\r\n}\r\n\r\n.listcolor:hover {\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n}\r\n\r\n.listcolor {\r\n  color: #345c66;\r\n  list-style-type: none;\r\n  font-size: 1.61rem;\r\n  white-space: nowrap;\r\n  max-width: 70%;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n  a{\r\n    color: #345c66;\r\n    text-decoration: none;\r\n  }\r\n}\r\n\r\n.listpadding {\r\n  padding-left: 0rem !important;\r\n}\r\n\r\n.omantext {\r\n  font-size: 2.03rem;\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
