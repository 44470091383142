const ar = {
    AF: 'أفغانستان',
    AL: 'ألبانيا',
    DZ: 'الجزائر',
    AS: 'ساموا الأمريكية',
    AD: 'أندورا',
    AO: 'أنغولا',
    AI: 'أنغويلا',
    AQ: 'أنتاركتيكا',
    AG: 'أنتيغوا وبربودا',
    AR: 'الأرجنتين',
    AM: 'أرمينيا',
    AW: 'أروبا',
    AU: 'أستراليا',
    AT: 'النمسا',
    AZ: 'أذربيجان',
    BS: 'الباهاما',
    BH: 'البحرين',
    BD: 'بنغلاديش',
    BB: 'بربادوس',
    BY: 'بيلاروس',
    BE: 'بلجيكا',
    BZ: 'بليز',
    BJ: 'بنين',
    BM: 'برمودا',
    BT: 'بوتان',
    BO: 'بوليفيا',
    BA: 'البوسنة والهرسك',
    BW: 'بوتسوانا',
    BR: 'البرازيل',
    IO: 'إقليم المحيط الهندي البريطاني',
    VG: 'جزر فيرجن البريطانية',
    BN: 'بروناي',
    BG: 'بلغاريا',
    BF: 'بوركينا فاسو',
    BI: 'بوروندي',
    CV: 'الرأس الأخضر',
    KH: 'كمبوديا',
    CM: 'الكاميرون',
    CA: 'كندا',
    KY: 'جزر كايمان',
    CF: 'جمهورية أفريقيا الوسطى',
    TD: 'تشاد',
    CL: 'تشيلي',
    CN: 'الصين',
    CX: 'جزيرة الكريسماس',
    CC: 'جزر كوكوس (كيلينغ)',
    CO: 'كولومبيا',
    KM: 'جزر القمر',
    CG: 'الكونغو - برازافيل',
    CD: 'الكونغو - كينشاسا',
    CK: 'جزر كوك',
    CR: 'كوستاريكا',
    HR: 'كرواتيا',
    CU: 'كوبا',
    CW: 'كوراكاو',
    CY: 'قبرص',
    CZ: 'التشيك',
    DK: 'الدنمارك',
    DJ: 'جيبوتي',
    DM: 'دومينيكا',
    DO: 'جمهورية الدومينيكان',
    EC: 'الإكوادور',
    EG: 'مصر',
    SV: 'السلفادور',
    GQ: 'غينيا الاستوائية',
    ER: 'إريتريا',
    EE: 'إستونيا',
    SZ: 'إسواتيني',
    ET: 'إثيوبيا',
    FK: 'جزر فوكلاند',
    FO: 'جزر فارو',
    FJ: 'فيجي',
    FI: 'فنلندا',
    FR: 'فرنسا',
    GF: 'غيانا الفرنسية',
    PF: 'بولينيزيا الفرنسية',
    GA: 'الغابون',
    GM: 'غامبيا',
    GE: 'جورجيا',
    DE: 'ألمانيا',
    GH: 'غانا',
    GI: 'جبل طارق',
    GR: 'اليونان',
    GL: 'غرينلاند',
    GD: 'غرينادا',
    GP: 'غوادلوب',
    GU: 'غوام',
    GT: 'غواتيمالا',
    GG: 'غيرنزي',
    GN: 'غينيا',
    GW: 'غينيا بيساو',
    GY: 'غيانا',
    HT: 'هايتي',
    HN: 'هندوراس',
    HK: 'هونغ كونغ',
    HU: 'هنغاريا',
    IS: 'أيسلندا',
    IN: 'الهند',
    ID: 'إندونيسيا',
    IR: 'إيران',
    IQ: 'العراق',
    IE: 'أيرلندا',
    IM: 'جزيرة مان',
    IL: 'إسرائيل',
    IT: 'إيطاليا',
    CI: 'ساحل العاج',
    JM: 'جامايكا',
    JP: 'اليابان',
    JE: 'جيرزي',
    JO: 'الأردن',
    KZ: 'كازاخستان',
    KE: 'كينيا',
    KI: 'كيريباتي',
    XK: 'كوسوفو',
    KW: 'الكويت',
    KG: 'قيرغيزستان',
    LA: 'لاوس',
    LV: 'لاتفيا',
    LB: 'لبنان',
    LS: 'ليسوتو',
    LR: 'ليبيريا',
    LY: 'ليبيا',
    LI: 'ليختنشتاين',
    LT: 'ليتوانيا',
    LU: 'لوكسمبورغ',
    MO: 'ماكاو',
    MK: 'مقدونيا',
    MG: 'مدغشقر',
    MW: 'مالاوي',
    MY: 'ماليزيا',
    MV: 'المالديف',
    ML: 'مالي',
    MT: 'مالطا',
    MH: 'جزر مارشال',
    MQ: 'مارتينيك',
    MR: 'موريتانيا',
    MU: 'موريشيوس',
    YT: 'مايوت',
    MX: 'المكسيك',
    FM: 'ميكرونيزيا',
    MD: 'مولدوفا',
    MC: 'موناكو',
    MN: 'منغوليا',
    ME: 'الجبل الأسود',
    MS: 'مونتسرات',
    MA: 'المغرب',
    MZ: 'موزمبيق',
    MM: 'ميانمار (بورما)',
    NA: 'ناميبيا',
    NR: 'ناورو',
    NP: 'نيبال',
    NL: 'هولندا',
    NC: 'كاليدونيا الجديدة',
    NZ: 'نيوزيلندا',
    NI: 'نيكاراغوا',
    NE: 'النيجر',
    NG: 'نيجيريا',
    NU: 'نيوي',
    NF: 'جزيرة نورفولك',
    KP: 'كوريا الشمالية',
    MP: 'جزر ماريانا الشمالية',
    NO: 'النرويج',
    OM: 'عمان',
    PK: 'باكستان',
    PW: 'بالاو',
    PS: 'فلسطين',
    PA: 'بنما',
    PG: 'بابوا غينيا الجديدة',
    PY: 'باراغواي',
    PE: 'بيرو',
    PH: 'الفلبين',
    PN: 'جزر بيتكيرن',
    PL: 'بولندا',
    PT: 'البرتغال',
    PR: 'بورتوريكو',
    QA: 'قطر',
    RE: 'روينيون',
    RO: 'رومانيا',
    RU: 'روسيا',
    RW: 'رواندا',
    WS: 'ساموا',
    SM: 'سان مارينو',
    ST: 'ساو تومي وبرينسيبي',
    SA: 'المملكة العربية السعودية',
    SN: 'السنغال',
    RS: 'صربيا',
    SC: 'سيشل',
    SL: 'سيراليون',
    SG: 'سنغافورة',
    SX: 'سينت مارتن',
    SK: 'سلوفاكيا',
    SI: 'سلوفينيا',
    SB: 'جزر سليمان',
    SO: 'الصومال',
    ZA: 'جنوب أفريقيا',
    KR: 'كوريا الجنوبية',
    SS: 'جنوب السودان',
    ES: 'إسبانيا',
    LK: 'سريلانكا',
    BL: 'سان بارتيلمي',
    SH: 'سانت هيلانة',
    KN: 'سانت كيتس ونيفيس',
    LC: 'سانت لوسيا',
    MF: 'سانت مارتن',
    PM: 'سانت بيير وميكلون',
    VC: 'سانت فنسنت وغرينادين',
    SD: 'السودان',
    SR: 'سورينام',
    SJ: 'سفالبارد ويان ماين',
    SE: 'السويد',
    CH: 'سويسرا',
    SY: 'سوريا',
    TW: 'تايوان',
    TJ: 'طاجيكستان',
    TZ: 'تنزانيا',
    TH: 'تايلاند',
    TL: 'تيمور- ليشتي',
    TG: 'توجو',
    TK: 'توكيلو',
    TO: 'تونغا',
    TT: 'ترينيداد وتوباغو',
    TN: 'تونس',
    TR: 'تركيا',
    TM: 'تركمانستان',
    TC: 'جزر تركس وكايكوس',
    TV: 'توفالو',
    UG: 'أوغندا',
    UA: 'أوكرانيا',
    AE: 'الإمارات العربية المتحدة',
    GB: 'المملكة المتحدة',
    US: 'الولايات المتحدة',
    UY: 'أوروغواي',
    UZ: 'أوزبكستان',
    VU: 'فانواتو',
    VA: 'مدينة الفاتيكان',
    VE: 'فنزويلا',
    VN: 'فيتنام',
    WF: 'والس وفوتونا',
    EH: 'الصحراء الغربية',
    YE: 'اليمن',
    ZM: 'زامبيا',
    ZW: 'زيمبابوي',
    AC: 'جزيرة أسنسيون',
    AX: 'جزر أولاند',
    BQ: 'بونير وسينت أوستاتيوس وسابا',
    TA: 'تريستان دا كونا',
    VI: 'جزر العذراء الأمريكية',
};

export default ar;

