import React, { createContext, useContext, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// Define the context type
interface TranslationContextType {
  getListdesc: (listId: string) => string;
  getPropertySubType: (subType: number) => string | null;
}

// Create the context with a default value
const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  const getListdesc = (listId: string) => {
    const ListId = {
      1: t('Rent'),
      2: t('Sale')
    };
    return ListId[listId] || t('Rent');
  };

  const getPropertySubType = (subType: number) => {
    const propertysubType = {
      1: t('Building'),
      2: t('TownHouse'),
      3: t('LowerPortion'),
      4: t('UpperPortion'),
      5: t('PentHouse'),
      6: t('Villa'),
      7: t('Room'),
      8: t('Flat'),
      9: t('MixUse'),
      10: t('WareHouse'),
      11: t('Shop'),
      12: t('BusinessCenter'),
      13: t('Factory'),
      14: t('Hall'),
      15: t('Plot'),
      16: t('Project')
    };
    return propertysubType[subType] || null;
  };

  const value = {
    getListdesc,
    getPropertySubType,
    // Add other translation-related functions here
  };

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = (): TranslationContextType => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslationContext must be used within a TranslationProvider');
  }
  return context;
};
