import React, { useEffect, useState, useRef, useCallback } from 'react';
import { marked } from 'marked';
import { postChatBox, getChatBox } from './ChatService';
import './Chatbox.css';
import botic from '../assets/icons/Bot.svg';
import botheader from '../assets/icons/botheader.svg';
import sendbut from '../assets/icons/SendButton.svg';
import i18n from "../reduxstate/i18n.js";

const Chat = () => {
  const boticon = 'https://d2og5lryw1ajtt.cloudfront.net/icons/Bot.png';
  const [showChatbox, setShowChatbox] = useState(false);
  const [chatmessages, setChatmessages] = useState([
    {
      text: "Hello I’m Mumtalikati Bot! 👋 I’m your personal assistant. How can I help you?",
      isUser: false,
      isTyping: false,
      displayedText: "Hello I’m Mumtalikati Bot! 👋 I’m your personal assistant. How can I help you?",
    },
  ]);
  const [chatloading, setChatloading] = useState(false);
  const [messageControl, setMessageControl] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef(null);
  const chatboxRef = useRef(null);

  const toggleChatbox = async () => {
    setShowChatbox(prevState => !prevState);
    try {
      await getChatBox();
    } catch (error) {
      console.error(error);
    }
  };

  const sendMessage = async () => {
    if (messageControl.trim() === '') return;
    
    const userMessage = messageControl;
    const chatRequest = {
      user: 123,
      prompt: userMessage,
    };

    // Add the user message to the chat
    const userChatMessage = { text: userMessage, isUser: true, displayedText: userMessage };
    setChatmessages(prevMessages => [...prevMessages, userChatMessage]);
    
    setChatloading(true);
    setMessageControl('');

    try {
      const data = await postChatBox(chatRequest);
      const chatbotMessage = { text: data.answer, isUser: false, isTyping: true, displayedText: '' };
      setChatmessages(prevMessages => [...prevMessages, chatbotMessage]);
      setChatloading(false);
    } catch (error) {
      console.error(error);
      setChatloading(false);
    }
  };

  const clearChat = () => {
    setChatmessages([
      {
        text: "Hello I’m Mumtalikati Bot! 👋 I’m your personal assistant. How can I help you?",
        isUser: false,
        isTyping: false,
        displayedText: "Hello I’m Mumtalikati Bot! 👋 I’m your personal assistant. How can I help you?",
      },
    ]);
    toggleChatbox();
  };

  const handleTypingEffect = (index, message) => {
    const words = message.split(' ');
    let wordIndex = 0;

    const typingInterval = setInterval(() => {
      setChatmessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        if (updatedMessages[index]) {
          updatedMessages[index].displayedText = words.slice(0, wordIndex + 1).join(' ');
          updatedMessages[index].isTyping = wordIndex < words.length - 1;
        }
        return updatedMessages;
      });
      wordIndex++;
      if (wordIndex === words.length) clearInterval(typingInterval);
    }, 150); // Adjust typing speed here
  };

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleScroll = () => {
    if (chatboxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatboxRef.current;
      setShowScrollButton(!(scrollTop + clientHeight >= scrollHeight));
    }
  };

  useEffect(() => {
    if (showChatbox) {
      scrollToBottom();
    }
  }, [chatmessages, showChatbox, scrollToBottom]);

  useEffect(() => {
    const chatbox = chatboxRef.current;
    if (chatbox) {
      chatbox.addEventListener('scroll', handleScroll);
      return () => chatbox.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (chatmessages.length > 0) {
      const lastMessageIndex = chatmessages.length - 1;
      const lastMessage = chatmessages[lastMessageIndex];
      if (!lastMessage.isUser && lastMessage.isTyping && !lastMessage.displayedText) {
        handleTypingEffect(lastMessageIndex, lastMessage.text);
      }
    }
  }, [chatmessages]);

  return (
    <div className="chatbox-container" dir={i18n.language === 'ar' ? 'ltr' : undefined}>
      {showChatbox && (
        <div className="chatbox">
          <div className="chatbox-header">
            <div className="chatbox-header-title">
              <img src={botheader} alt="Chatbot Icon" />
              <h1 className="fs-3">Mumtalikati Live Chat</h1>
            </div>
            <div>
              <button onClick={toggleChatbox} className="chatbox-header-button minimize-button">−</button>
              <button onClick={clearChat} className="chatbox-header-button">✕</button>
            </div>
          </div>
          <section className="chatbox-messages" ref={chatboxRef}>
            {chatmessages.map((message, index) => (
              <div className={`chatbox-message ${message.isUser ? 'user-message' : 'chatbot-message'}`} key={index}>
                {!message.isUser && <img src={botic} alt="Chatbot" />}
                <span className={message.isUser ? 'user-text' : 'aximo-title-animation'}
                  dangerouslySetInnerHTML={{
                    __html: marked(message.displayedText || ''),
                  }}
                />
                {message.isUser && <div className="user-initial">M</div>}
              </div>
            ))}
            {chatloading && (
              <div className="chatbox-message chatbot-message">
                <img src={botic} alt="Chatbot" />
                <div className="loading-dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </section>
          {showScrollButton && (
            <button className="scroll-down-button" onClick={scrollToBottom}>
              ↓
            </button>
          )}
          <footer className="chatbox-footer">
            <input
              value={messageControl}
              onChange={e => setMessageControl(e.target.value)}
              placeholder="Type a message to Mumtalikati Bot..."
              onKeyDown={e => e.key === 'Enter' && sendMessage()}
            />
            <button onClick={sendMessage} className="send-button">
              <img src={sendbut} alt="Send" />
            </button>
          </footer>
        </div>
      )}
      {!showChatbox && (
        <div className="chatbox-toggle-button" onClick={toggleChatbox}>
          <img className='chatbotimg' src={boticon} alt="Open Chatbot" width={'62px'} height={'62px'} />
        </div>
      )}
    </div>
  );
};

export default Chat;
