// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.office {
  color: #23262AB2;

}

.office-t {
  color: #111111;

}

.contactcard {
  box-shadow: 0px 20px 25px 0px #B3B3B340;
  border: transparent;
  border-radius: 60px !important;
}

.contactform {
  /* border-color: #000; */
  padding: 1.3rem;
  border-radius: 10px;
  border: 1.5px solid #D3D3D3
}

.contactusbtn {
  height: 50px;
  align-items: center;
  cursor: pointer;
  background-color: #bb2525;
  color: white;
  border-radius: 25px;
}

.contacttext {
  font-family: Poppins;
  font-size: 4vw;
  font-weight: 600;
  color: #FFFFFF;

}
.contact-hero {
  position: absolute;
  top: 40%;
  width: 100%;

}
.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Styling/Contactus.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;;AAElB;;AAEA;EACE,cAAc;;AAEhB;;AAEA;EACE,uCAAuC;EACvC,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,mBAAmB;EACnB;AACF;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,cAAc;EACd,gBAAgB;EAChB,cAAc;;AAEhB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;;AAEb;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf;AACA;;EAEE,wBAAwB;EACxB,SAAS;AACX","sourcesContent":[".office {\r\n  color: #23262AB2;\r\n\r\n}\r\n\r\n.office-t {\r\n  color: #111111;\r\n\r\n}\r\n\r\n.contactcard {\r\n  box-shadow: 0px 20px 25px 0px #B3B3B340;\r\n  border: transparent;\r\n  border-radius: 60px !important;\r\n}\r\n\r\n.contactform {\r\n  /* border-color: #000; */\r\n  padding: 1.3rem;\r\n  border-radius: 10px;\r\n  border: 1.5px solid #D3D3D3\r\n}\r\n\r\n.contactusbtn {\r\n  height: 50px;\r\n  align-items: center;\r\n  cursor: pointer;\r\n  background-color: #bb2525;\r\n  color: white;\r\n  border-radius: 25px;\r\n}\r\n\r\n.contacttext {\r\n  font-family: Poppins;\r\n  font-size: 4vw;\r\n  font-weight: 600;\r\n  color: #FFFFFF;\r\n\r\n}\r\n.contact-hero {\r\n  position: absolute;\r\n  top: 40%;\r\n  width: 100%;\r\n\r\n}\r\n.no-arrow {\r\n  -moz-appearance: textfield;\r\n}\r\n.no-arrow::-webkit-inner-spin-button {\r\n  display: none;\r\n}\r\n.no-arrow::-webkit-outer-spin-button,\r\n.no-arrow::-webkit-inner-spin-button {\r\n  -webkit-appearance: none;\r\n  margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
