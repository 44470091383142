import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  AreasList: null,
  propertyList: null,
  propertyMasterList: null,
  propertyWaliyatList: null,
  propertySubTypesList: null,
  minVal: 1,
  maxVal: 1000,
  currentMinVal: 1,
  currentMaxVal: 1000,
  popularAreasData:null,
  loading: false,
  listingsData:null,
  selectedArea:null,
  propertyData: null,
  wilayatBuildingtop8List:null,
};

const areasListSlice = createSlice({
  name: "Areas",
  initialState,
  reducers: {
    dataAllAreas: (state, action) => {
      state.AreasList = [...action.payload];
    },
    propertylist: (state, action) => {
      state.propertyList = [...action.payload];
    },
    propertyMasterList: (state, action) => {
      state.propertyMasterList = [...action.payload];
    },
    propertyWatiyatList: (state, action) => {
      state.propertyWaliyatList = [...action.payload];
    },
    propertySubTypesList: (state, action) => {
      state.propertySubTypesList = [...action.payload];
    },
    popularAreasData: (state, action) => {
      state.popularAreasData = action.payload; 
    },
    listingsData: (state, action) => {
      state.listingsData = [...action.payload]; 
    },
    updateMinValue: (state, action) => {
      state.currentMinVal = action.payload.value;
    },
    updateMaxValue: (state, action) => {
      state.currentMaxVal = action.payload.value;
    },
    loaderpage: (state, action) => {
      state.loading = action.payload.value;
    },
    selecteddata: (state, action) => {
      state.selectedArea = action.payload;
    },
    updatePropertyData: (state, action) => {
      state.propertyData = action.payload; 
    },
    getwilayatBuildingtop8: (state, action) => {
      state.wilayatBuildingtop8List = action.payload; 
    },
    resetApiCall: (state, action) => {
      state.propertyList = null;
      state.propertyWaliyatList = null;
      state.propertyMasterList = null;
      state.propertySubTypesList = null;
      state.listingsData=null;
      state.AreasList = null;
      state.wilayatBuildingtop8List=null;
      state.popularAreasData=null;
    }
  }
});

export const {
  dataAllAreas,
  propertylist,
  propertyMasterList,
  propertyWatiyatList,
  propertySubTypesList,
  popularAreasData,
  updateMinValue,
  updateMaxValue,
  listingsData,
  resetApiCall,loaderpage,
  selecteddata,
  updatePropertyData,
  getwilayatBuildingtop8,
} = areasListSlice.actions;

export default areasListSlice.reducer;
