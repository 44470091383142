import { TranslationProvider } from '../src/language/TranslationContext';
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./reduxstate/store.js";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { I18nextProvider } from 'react-i18next';
import i18n from "./reduxstate/i18n.js";
import { Provider } from "react-redux";
import Router from "./routers/Router";
import WebFont from "webfontloader";
import 'regenerator-runtime/runtime';
import Loading from "./loading";
import "./reduxstate/i18n.js";
import "./style.scss";
import React from "react";
import '@babel/polyfill';
import Topbar from './sharedcomponents/Topbar';
import Footer from './sharedcomponents/Footer';
import Chat from '../src/pages/Chatbox';
import { BrowserRouter } from "react-router-dom";

WebFont.load({
  google: {
    families: ["Satisfy", "Inter", "Roboto", "Poppins", "Lato"],
  },
});
export default function Root() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Suspense fallback={<Loading />}>
          <I18nextProvider i18n={i18n}>
            <TranslationProvider>
              <BrowserRouter>
                <Topbar />
                <Router />
                <Chat />
                <Footer />
              </BrowserRouter>
            </TranslationProvider>
          </I18nextProvider>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
