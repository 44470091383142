import React, { useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; 
import { useTranslation } from 'react-i18next';
import './PhoneNumber.scss'; 
import i18n from "../../reduxstate/i18n.js";
import ar from './locales/ar';
import en from './locales/en'
const PhoneNumber = ({ userName, onUserNameChange }) => {
    const { t } = useTranslation();

    const [value, setValue] = useState(userName);

    const handleChange = (newValue) => {
        
        setValue(newValue);

        onUserNameChange(newValue);
    };

    return (
   <>
        <div>
            <PhoneInput
            placeholder={t('Enterphonenumber')}
            className={`form-control contactform f14 input-phone-number ${i18n.language === 'ar' ? 'placeholder-ltr' : 'placeholder-rtl'}`}
            defaultCountry='OM'
            value={value}
            labels={i18n.language === 'ar' ? ar : en}
            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
            onChange={handleChange}
   
        />
     
     
         {value && !isValidPhoneNumber(value) && (
                <p className="text-danger">Invalid phone number</p>
            )}
        </div>
         </>      
    );
};

export default PhoneNumber;
