// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@mixin sm {
  @media (max-width: 738px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1366px) {
    @content;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Styling/responsive.scss"],"names":[],"mappings":"AAAA;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,QAAQ;EACV;AACF","sourcesContent":["@mixin sm {\r\n  @media (max-width: 738px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin md {\r\n  @media (max-width: 1024px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin lg {\r\n  @media (max-width: 1366px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
