import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  error: null,
  loading: false,
  propertySubType:null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    dataStart: (state) => {
      state.loading = true;
    },
    dataSuccess: (state, action) => {
      state.currentUser = action.payload;
      state.loading = false;
      state.error = null;
    },
    dataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    OutUserSuccess: (state) => {
      state.currentUser = null;
      state.loading = false;
      state.error = null;
    },
    getPropertySubTypes:(state, action)=>{
      state.propertySubType = action.payload;
    }
  },
});

export const { dataSuccess, dataStart, dataFailure, OutUserSuccess,getPropertySubTypes } =
  userSlice.actions;

export default userSlice.reducer;
