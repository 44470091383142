// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1401px) {
    .tab-btn{
        font-size: 1rem;
      }
      
    }`, "",{"version":3,"sources":["webpack://./src/Styling/homepage.css"],"names":[],"mappings":"AAAA;IACI;QACI,eAAe;MACjB;;IAEF","sourcesContent":["@media (min-width: 1401px) {\r\n    .tab-btn{\r\n        font-size: 1rem;\r\n      }\r\n      \r\n    }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
