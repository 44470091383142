// ChatService.js
import axios from 'axios';

const CHAT_API_URL = 'https://991kepisg5.execute-api.ap-south-1.amazonaws.com/Prod/chat';
const CHAT_API_health = 'https://991kepisg5.execute-api.ap-south-1.amazonaws.com/Prod/health';
export const postChatBox = async (chatRequest) => {
  try {
    const response = await axios.post(CHAT_API_URL, { request: chatRequest });
    return response.data;
  } catch (error) {
    console.error('Error posting chat request:', error);
    throw error;
  }
};
export const getChatBox = async () => {
  try {
    const response = await axios.get(CHAT_API_health);
    return response.data;
  } catch (error) {
    console.error('Error posting chat request:', error);
    throw error;
  }
};